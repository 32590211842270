export default {
  colors: {
    primary: '#3066BE',
    background: '#FFFFFF',
    shape: `#F2F2FA`,
    title: `#3D3D4D`,
    text: `#6C6C80`,
    components: {
      blockquote: {
        background: `#f9d87a`,
        text: `#000000`,
      },
    },
  },
};
